<template id="matchcard">
  <b-card class="m-1 text-center h-100">
    <h5 @click="openMatch" style="cursor: pointer">
      <b-img :src="logoHome" fluid :width="50"></b-img>{{ home.officialName }} -
      {{ away.officialName }} <b-img :src="logoAway" fluid :width="50"></b-img>
    </h5>
    <div>{{ home.pivot.score }} - {{ away.pivot.score }}</div>
    <div>{{ match.data | formatDateHour }}</div>
    <b-row class="mt-2">
      <b-col cols="6">
        <b-row align-v="center" v-for="player in playersHome" :key="player.id">
          <b-col class="text-left" cols="8">
            <b-link
              :to="{ name: 'ScoutingEvalPlayer', params: { id: player.id } }"
              target="_blank"
            >
              <b-img :src="player.avatar" height="50"></b-img><br />
              <div
                :title="player.nome"
                style="
                  flex: 1;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                "
              >
                {{ player.nome }}
              </div>
            </b-link>
          </b-col>
          <b-col class="border-right">
            <b-row align-h="center">
              <b-col
                v-for="val in player.valutazioni"
                :key="match.id + '_' + val.id"
                :id="'popover-target-' + match.id + '_' + val.id"
                cols="1"
              >
                <div v-if="auth.isUserEnable('opta')">
                  <div v-if="!val.gradimento" style="width: 35px; height: 35px">
                    <b-icon icon="circle-slash"></b-icon>
                  </div>
                  <grad1 class="grad" v-if="val.gradimento == 1" />
                  <grad2 class="grad" v-if="val.gradimento == 2" />
                  <grad3 class="grad" v-if="val.gradimento == 3" />
                  <grad4 class="grad" v-if="val.gradimento == 4" />
                  <grad5 class="grad" v-if="val.gradimento == 5" />
                </div>
                <div v-else style="min-width: 35px; height: 35px">
                  <div v-if="val.gradimento" style="font-size: 0.5rem">
                    {{ labelGrad[val.gradimento - 1] }}
                  </div>
                  <div v-if="val.gradimento">
                    {{ siglaGrad[val.gradimento - 1] }}
                  </div>
                  <b-icon v-if="!val.gradimento" icon="circle-slash"></b-icon>
                </div>
                <b-popover
                  :target="'popover-target-' + match.id + '_' + val.id"
                  triggers="hover"
                  placement="top"
                >
                  <template v-slot:title
                    >{{ val.utente }} {{ val.data | formatDate }}</template
                  >
                  {{ val.note }}
                </b-popover>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="6">
        <b-row align-v="center" v-for="player in playersAway" :key="player.id">
          <b-col class="border-left">
            <b-row align-h="center">
              <b-col
                v-for="val in player.valutazioni"
                :key="match.id + '_' + val.id"
                :id="'popover-target-' + match.id + '_' + val.id"
                cols="1"
              >
                <div v-if="auth.isUserEnable('opta')">
                  <div v-if="!val.gradimento" style="width: 35px; height: 35px">
                    <b-icon icon="circle-slash"></b-icon>
                  </div>
                  <grad1 class="grad" v-if="val.gradimento == 1" />
                  <grad2 class="grad" v-if="val.gradimento == 2" />
                  <grad3 class="grad" v-if="val.gradimento == 3" />
                  <grad4 class="grad" v-if="val.gradimento == 4" />
                  <grad5 class="grad" v-if="val.gradimento == 5" />
                </div>
                <div v-else style="min-width: 35px; height: 35px">
                  <div v-if="val.gradimento" style="font-size: 0.5rem">
                    {{ labelGrad[val.gradimento - 1] }}
                  </div>
                  <div v-if="val.gradimento">
                    {{ siglaGrad[val.gradimento - 1] }}
                  </div>
                  <b-icon v-if="!val.gradimento" icon="circle-slash"></b-icon>
                </div>
                <b-popover
                  :target="'popover-target-' + match.id + '_' + val.id"
                  triggers="hover"
                  placement="top"
                >
                  <template v-slot:title
                    >{{ val.utente }} {{ val.data | formatDate }}</template
                  >
                  {{ val.note }}
                </b-popover>
              </b-col>
            </b-row>
          </b-col>
          <b-col class="text-right" cols="8">
            <b-link
              :to="{ name: 'ScoutingEvalPlayer', params: { id: player.id } }"
              target="_blank"
            >
              <b-img :src="player.avatar" height="50"></b-img><br />
              <div
                :title="player.nome"
                style="
                  flex: 1;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                "
              >
                {{ player.nome }}
              </div>
            </b-link>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import Auth from "@/lib/auth.js";
import moment from "moment";
import grad1 from "@/assets/images/grad1.svg";
import grad2 from "@/assets/images/grad2.svg";
import grad3 from "@/assets/images/grad3.svg";
import grad4 from "@/assets/images/grad4.svg";
import grad5 from "@/assets/images/grad5.svg";
export default {
  props: {
    match: {
      type: Object,
      required: true,
    },
  },

  data: function () {
    return {
      labelGrad: ["WIN", "QUALIFY", "GO", "STAY", "WIN"],
      siglaGrad: ["TOP", "CL", "EL", "A", "B"],
    };
  },

  components: {
    grad1,
    grad2,
    grad3,
    grad4,
    grad5,
  },

  created: function () {},

  computed: {
    auth: function () {
      return Auth;
    },

    user: function () {
      return Auth.user;
    },

    home() {
      for (var i in this.match.squadre) {
        var team = this.match.squadre[i];
        if (team.pivot.side == "home") {
          return team;
        }
      }
      return null;
    },

    away() {
      for (var i in this.match.squadre) {
        var team = this.match.squadre[i];
        if (team.pivot.side == "away") {
          return team;
        }
      }
      return null;
    },
    logoHome() {
      for (var i in this.match.squadre) {
        var team = this.match.squadre[i];
        if (team.pivot.side == "home") {
          return team.logo_url;
        }
      }
      return null;
    },

    logoAway() {
      for (var i in this.match.squadre) {
        var team = this.match.squadre[i];
        if (team.pivot.side == "away") {
          return team.logo_url;
        }
      }
      return null;
    },
    playersHome() {
      var player_ids = [];
      var players = [];
      var found = false;
      for (var i in this.match.valutazioni) {
        var valutazione = this.match.valutazioni[i];
        var giocatore = valutazione.osservato.giocatore;
        found = false;
        if (this.home.type == "national") {
          if (this.home.paese_id == giocatore.paese_nascita_id) {
            found = true;
          }
        } else {
          for (var j in giocatore.carriera) {
            if (giocatore.carriera[j].squadra_id == this.home.id) {
              found = true;
              break;
            }
          }
        }
        if (found) {
          if (player_ids.indexOf(giocatore.id) > -1) {
            var val = {
              id: valutazione.id,
              note: valutazione.note,
              gradimento: valutazione.gradimento_id,
              data: valutazione.created_at,
              utente: valutazione.utente ? valutazione.utente.nickname : "",
            };
            players[player_ids.indexOf(giocatore.id)].valutazioni.push(val);
          } else {
            var valutazioni = [];
            var val = {
              id: valutazione.id,
              note: valutazione.note,
              gradimento: valutazione.gradimento_id,
              data: valutazione.created_at,
              utente: valutazione.utente ? valutazione.utente.nickname : "",
            };
            valutazioni.push(val);
            var player = {
              id: giocatore.id,
              nome: giocatore.cognome + " " + giocatore.nome,
              avatar: giocatore.avatar_url,
              valutazioni: valutazioni,
            };
            players.push(player);
            player_ids.push(player.id);
          }
        }
      }
      return players;
    },
    playersAway() {
      var player_ids = [];
      var players = [];
      var found = false;
      for (var i in this.match.valutazioni) {
        var valutazione = this.match.valutazioni[i];
        var giocatore = valutazione.osservato.giocatore;
        found = false;
        if (this.away.type == "national") {
          if (this.away.paese_id == giocatore.paese_nascita_id) {
            found = true;
          }
        } else {
          for (var j in giocatore.carriera) {
            if (giocatore.carriera[j].squadra_id == this.away.id) {
              found = true;
              break;
            }
          }
        }
        if (found) {
          if (player_ids.indexOf(giocatore.id) > -1) {
            var val = {
              id: valutazione.id,
              note: valutazione.note,
              gradimento: valutazione.gradimento_id,
              data: valutazione.created_at,
              utente: valutazione.utente ? valutazione.utente.nickname : "",
            };
            players[player_ids.indexOf(giocatore.id)].valutazioni.push(val);
          } else {
            var valutazioni = [];
            var val = {
              id: valutazione.id,
              note: valutazione.note,
              gradimento: valutazione.gradimento_id,
              data: valutazione.created_at,
              utente: valutazione.utente ? valutazione.utente.nickname : "",
            };
            valutazioni.push(val);
            var player = {
              id: giocatore.id,
              nome: giocatore.cognome + " " + giocatore.nome,
              avatar: giocatore.avatar_url,
              valutazioni: valutazioni,
            };
            players.push(player);
            player_ids.push(player.id);
          }
        }
      }
      return players;
    },
  },

  methods: {
    openMatch() {
      this.$emit("openMatch", this.match.wyscout_id);
    },
  },

  filters: {
    formatDateHour: function (date) {
      var m = moment(date);
      if (m.isValid()) {
        return m.format("DD/MM/YYYY HH:mm");
      }
      return "";
    },
    formatDate: function (date) {
      var m = moment(date);
      if (m.isValid()) {
        return m.format("DD/MM/YYYY");
      }
      return "";
    },
  },
};
</script>
